export const getOrCreateTooltip = (chart, querySelector = 'chartjs-tooltip') => {
  let tooltipEl = chart.canvas.parentNode.querySelector(`#${querySelector}`);
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { tooltip, chart } = context;
  const getBody = (item) => ({
    body: item.lines,
    after: item.after,
  });

  const tooltipEl = getOrCreateTooltip(chart);

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.remove();
    // tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(getBody);
    // Создаем контейнер
    const container = document.createElement('div');
    container.classList.add('d-flex', 'flex-column');

    bodyLines.forEach((item, i) => {
      const colors = tooltip.labelColors[i];
      // Создаём лейбл легенды
      const legend = document.createElement('div');
      legend.classList.add('chartjs-tooltip__key', 'd-flex');
      legend.style.backgroundColor = colors.backgroundColor;
      legend.style.borderColor = colors.borderColor;
      legend.style.borderWidth = '2px';
      // значения
      const legendText = document.createElement('div');
      legendText.classList.add('chartjs-tooltip__text');
      const legendTextContent = document.createTextNode(item.body);
      legendText.appendChild(legendTextContent);
      // Текст
      const legendLabel = document.createElement('div');
      legendLabel.classList.add('chartjs-tooltip__label');

      const legendLabelContent = document.createTextNode(item.after);
      legendLabel.appendChild(legendLabelContent);
      const labelContent = document.createElement('div');
      labelContent.classList.add('d-flex', 'flex-column');
      labelContent.appendChild(legendText);
      labelContent.appendChild(legendLabel);
      const row = document.createElement('div');
      row.classList.add('d-flex', 'align-baseline');
      row.appendChild(legend);
      row.appendChild(labelContent);
      if (i < bodyLines.length - 1) {
        row.classList.add('mb-1');
      }
      container.appendChild(row);
    });
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }
    const caret = document.createElement('div');
    caret.className = '';

    if (tooltip.yAlign) {
      caret.classList.add('chartjs-tooltip__carret', `y--${tooltip.yAlign}`);
    } else {
      caret.classList.add('y--no-carret');
    }
    if (tooltip.xAlign) {
      caret.classList.add('chartjs-tooltip__carret', `x--${tooltip.xAlign}`);
    } else {
      caret.classList.add('x--no-carret');
    }
    caret.style.top = `${(positionY + tooltip.caretY)
  - (positionY + tooltip.caretY - tooltip.height / 2)}px`;
    tooltipEl.appendChild(container);
    tooltipEl.appendChild(caret);
  }

  tooltipEl.style.opacity = 1;
  if (tooltip.xAlign === 'right') {
    tooltipEl.style.left = `${positionX + tooltip.caretX - tooltipEl.clientWidth - 12}px`;
  } else if (tooltip.xAlign === 'center') {
    tooltipEl.style.left = `${positionX + tooltip.caretX - tooltipEl.clientWidth / 2}px`;
  } else {
    tooltipEl.style.left = `${positionX + tooltip.caretX + 12}px`;
  }
  if (tooltip.yAlign === 'center') {
    tooltipEl.style.top = `${positionY + tooltip.caretY - tooltip.height / 2}px`;
  } else if (tooltip.yAlign === 'top') {
    tooltipEl.style.top = `${positionY + tooltip.caretY + 12}px`;
  } else {
    tooltipEl.style.top = `${positionY + tooltip.caretY - tooltip.height - 12}px`;
  }
};
